import React from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'
import { logo, menuNotSelectedbg, menuSelectedbg } from 'images'
import { MEDIA_QUERY } from 'styles'

const CategoryItemView = ({ imageUrl, isSelected, nameFormatted }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {!!imageUrl ? (
          <img className={styles.image} src={imageUrl} alt="Category" />
        ) : (
          <img className={styles.logoImage} src={logo} alt="Recent Orders" />
        )}
        <div
          className={styles.imageCover}
          style={isSelected ? { backgroundColor: 'transparent' } : {}}
        />
      </div>
      <CFView
        center
        className={isSelected ? styles.textSelected : styles.notSelected}
      >
        <Text heading2 bold center className={styles.text}>
          {nameFormatted}
        </Text>
      </CFView>
    </div>
  )
}

const styles = {
  container: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    height: '20vw',
    width: '20vw',
    maxHeight: '21rem',
    maxWidth: '21rem',
    marginTop: '0.5rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      height: '43vw',
      width: '43vw',
    },
  }),
  imageContainer: css({
    height: '75%',
    width: '95%',
    position: 'relative',
    paddingBottom: '.5rem',
  }),
  image: css({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 2,
  }),
  logoImage: css({
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
    padding: '1rem',
    zIndex: 2,
  }),
  imageCover: css({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 3,
    [MEDIA_QUERY.MD]: {
      backgroundColor: 'transparent',
    },
  }),
  text: css({
    zIndex: 4,
    fontSize: 22,
  }),
  textSelected: css({
    position: 'relative',
    top: '-8%',
    zIndex: 4,
    width: '95%',
    fontSize: 22,
    color: '#40B194',
    paddingTop: '1rem',
    background: `url(${menuSelectedbg}) no-repeat`,
    height: '5rem',
    backgroundSize: 'cover',
  }),
  notSelected: css({
    position: 'relative',
    top: '-8%',
    zIndex: 4,
    width: '95%',
    fontSize: 22,
    color: 'white',
    paddingTop: '1rem',
    background: `url(${menuNotSelectedbg}) no-repeat`,
    height: '5rem',
    backgroundSize: 'cover',
  }),
}

export default CategoryItemView
