import React from 'react'
import { css } from 'emotion'
import { orderButton } from 'images'

export default () => (
  <img src={orderButton} alt="Order Now" className={styles.orderButton} />
)

const styles = {
  orderButton: css({
    width: '30rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
}
