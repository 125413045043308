import { db, firebaseAuth, DELETE_FIELD_VALUE } from 'cf-core/src/config/firebase'
import { TYPES } from 'cf-core/src/constants'
import * as utils from 'cf-utils'
import * as api from '../../api'
import { get, omit, isEmpty, orderBy, keys } from 'lodash'
import paymentActions from './paymentActions'

const DEFAULT_STATE = {
  // User Restaurant data
  cart: {},
  recentOrders: {},
  reviewOrderId: '',
  points: 0,
  promos: {},
  orderType: '',
  choicesCart: [],

  // User data
  id: '',
  payment: {},
  phoneNumber: '',
  email: '',
  name: '',
  address: '',
  latLng: {},
  aptNumber: '',
  deliveryInstructions: '',
  loading: false,
  loadingUserRestaurant: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isPlacingOrder: false,
  notificationToken: '',

  // User Last Order
  lastUserOrder: {},
  loadingLastUserOrder: null,

  // local only
  notes: '',
  tableId: '',
  tip: 0.15,
}

export default (stripeKey, serverUrl) => ({
  state: DEFAULT_STATE,
  reducers: {
    _setUser: (state, user) => ({
      ...state,
      ...user,
    }),
    _unsetUser: () => DEFAULT_STATE,
    _setLastUserOrder: (state, lastUserOrder) => ({
      ...state,
      lastUserOrder,
    }),
    setIsPlacingOrder: (state, isPlacingOrder) => ({
      ...state,
      isPlacingOrder,
    }),
    setIsLoggingIn: (state, isLoggingIn) => ({
      ...state,
      isLoggingIn,
    }),
    setIsLoggingOut: (state, isLoggingOut) => ({
      ...state,
      isLoggingOut,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setLoadingUserRestaurant: (state, loadingUserRestaurant) => ({
      ...state,
      loadingUserRestaurant,
    }),
    setLoadingLastUserOrder: (state, loadingLastUserOrder) => ({
      ...state,
      loadingLastUserOrder,
    }),
    _setAddress: (state, { address, aptNumber, deliveryInstructions, latLng }) => ({
      ...state,
      address,
      aptNumber,
      deliveryInstructions,
      latLng,
    }),
    _setCart: (state, cart) => ({
      ...state,
      cart,
    }),
    _setChoicesCart: (state, choicesCart) => ({
      ...state,
      choicesCart,
    }),
    _addCartItem: (state, { id, qty }) => ({
      ...state,
      cart: {
        ...state.cart,
        [id]: {
          id,
          count: get(state.cart[id], 'count', 0) + qty,
        },
      },
    }),
    _subtractCartItem: (state, { id }) => ({
      ...state,
      cart:
        state.cart[id].count === 1
          ? omit(state.cart, id)
          : {
              ...state.cart,
              [id]: {
                ...state.cart[id],
                count: state.cart[id].count - 1,
              },
            },
    }),
    _clearCart: state => ({
      ...state,
      cart: DEFAULT_STATE.cart,
      choicesCart: DEFAULT_STATE.choicesCart,
    }),
    _addChoicesCartItem: (state, product) => ({
      ...state,
      choicesCart: [...state.choicesCart, product],
    }),
    _subtractChoicesCartItem: (state, choiceIndex) => ({
      ...state,
      choicesCart: state.choicesCart.reduce((prev, choice, index) => {
        if (index === choiceIndex && choice.count > 1) {
          prev.push(choice)
          choice.count--
        } else if (index !== choiceIndex) {
          prev.push(choice)
        }
        return prev
      }, []),
    }),
    _addRecentOrderProducts(state, products) {
      const productIdsObj = Object.keys(products).reduce((prev, next) => {
        prev[next] = true
        return prev
      }, {})
      return {
        ...state,
        recentOrders: [...productIdsObj, ...state.recentOrders],
      }
    },
    setNotes(state, notes) {
      return {
        ...state,
        notes,
      }
    },
    _setOrderType(state, orderType) {
      return {
        ...state,
        orderType,
      }
    },
    setTableId(state, tableId) {
      return {
        ...state,
        tableId,
      }
    },
    setTip(state, tip) {
      return {
        ...state,
        tip,
      }
    },
    _clearNotes(state) {
      return {
        ...state,
        notes: DEFAULT_STATE.notes,
      }
    },
    clearRecentOrders(state) {
      return {
        ...state,
        recentOrders: DEFAULT_STATE.recentOrders,
      }
    },
    _clearPromos(state) {
      return {
        ...state,
        promos: DEFAULT_STATE.promos,
      }
    },
  },
  actions: ({ dispatch, getState }) => ({
    ...paymentActions({ dispatch, getState, stripeKey, serverUrl }),
    getUserToken() {
      return api.auth.getUserToken()
    },
    getUserId() {
      return get(api.auth.getCurrentUser(), 'uid')
    },
    getCart() {
      const cart = getState().user.cart
      const activeProducts = dispatch.restaurant.getActiveProducts()
      return Object.keys(cart).reduce((prev, id) => {
        if (activeProducts[id]) {
          prev[id] = cart[id]
        }
        return prev
      }, {})
    },
    getChoicesCart() {
      const cart = getState().user.choicesCart
      const activeProducts = dispatch.restaurant.getActiveProducts()
      return cart.reduce((prev, { id }, index) => {
        if (activeProducts[id]) {
          prev.push(cart[index])
        }
        return prev
      }, [])
    },
    getIsCartEmpty() {
      return dispatch.user.getCartCount() === 0
    },
    getIsDineIn() {
      return !!getState().user.tableId
    },
    async getIsEmailExists(email) {
      if (!email) {
        throw new Error('Email cannot be empty')
      }
      return await api.auth.isEmailExists(email)
    },
    getIsLoggingIn() {
      return getState().user.isLoggingIn
    },
    getIsLoggingOut() {
      return getState().user.isLoggingOut
    },
    getIsLoading() {
      return getState().user.loading || getState().user.loadingUserRestaurant || !!getState().user.loadingLastUserOrder
    },
    getIsPlacingOrder() {
      return getState().user.isPlacingOrder
    },
    getIsLoggedIn() {
      return !!api.auth.getCurrentUser()
    },
    getEmail() {
      return api.auth.getCurrentUser().email
    },
    getPhoneNumber() {
      return getState().user.phoneNumber
    },
    getName() {
      return getState().user.name
    },
    getAddress() {
      return getState().user.address
    },
    getLatLng() {
      return getState().user.latLng
    },
    getAptNumber() {
      return getState().user.aptNumber
    },
    getDeliveryInstructions() {
      return getState().user.deliveryInstructions
    },
    getRecentOrders() {
      return getState().user.recentOrders
    },
    getRecentOrdersSorted() {
      const activeProducts = dispatch.restaurant.getActiveProducts()

      const recentOrders = dispatch.user.getRecentOrders()
      const validRecentOrders = Object.keys(recentOrders).reduce((prev, productId) => {
        if (activeProducts[productId]) {
          prev[productId] = recentOrders[productId]
        }
        return prev
      }, {})

      return orderBy(keys(validRecentOrders), k => validRecentOrders[k], 'desc')
    },
    getFirstOrderDiscountAmount() {
      const firstOrderDiscount = dispatch.restaurant.getFirstOrderDiscount()
      if (dispatch.user.getIsFirstOrder() && firstOrderDiscount > 0) {
        return dispatch.user.getCartSubTotal() * firstOrderDiscount
      } else {
        return 0
      }
    },
    getCartWithProductDetails() {
      const restaurantProducts = dispatch.restaurant.getProducts()
      const cartItems = dispatch.user.getCart()
      return Object.keys(cartItems).reduce((prev, cartId) => {
        prev[cartId] = {
          ...cartItems[cartId],
          ...restaurantProducts[cartId],
        }
        return prev
      }, {})
    },
    getChoicesCartWithProductDetails() {
      const restaurantProducts = dispatch.restaurant.getProducts()
      const cartItems = dispatch.user.getChoicesCart()
      return cartItems.map(product => {
        const totalPrice = product.choicesPrice + restaurantProducts[product.id].price
        return {
          ...product,
          ...restaurantProducts[product.id],
          totalPrice,
        }
      })
    },
    getCartCount() {
      const cartCount = Object.values(dispatch.user.getCart()).reduce((prev, product) => {
        return (prev += product.count)
      }, 0)
      const choicesCartCount = dispatch.user.getChoicesCart().reduce((prev, choice) => {
        return (prev += choice.count)
      }, 0)
      const promosCount = Object.values(dispatch.user.getValidPromosWithDetails()).reduce((prev, product) => {
        return (prev += product.count)
      }, 0)
      return cartCount + choicesCartCount + promosCount
    },
    getCartSubTotal() {
      const cartProducts = dispatch.user.getCartWithProductDetails()
      const cartSubtotal = Object.keys(cartProducts).reduce((prev, id) => {
        return prev + cartProducts[id].price * cartProducts[id].count
      }, 0)

      const choicesCartProducts = dispatch.user.getChoicesCartWithProductDetails()
      const choicesCartSubtotal = choicesCartProducts.reduce((prev, product) => {
        return prev + product.totalPrice * product.count
      }, 0)

      return cartSubtotal + choicesCartSubtotal
    },
    getCartDiscount() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)

      let totalDiscount = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalDiscountSubtotal > 0) {
          totalDiscount += promoDetails.totalDiscountSubtotal
        }
      }

      totalDiscount += dispatch.user.getFirstOrderDiscountAmount()

      // Cannot get discount more than cartSubTotal
      return Math.min(totalDiscount, dispatch.user.getCartSubTotal())
    },
    getCartTax() {
      const deliveryFee = dispatch.user.getOrderType() === 'Delivery' ? dispatch.restaurant.getDeliveryFee() : 0
      return (dispatch.user.getCartSubTotal() + deliveryFee - dispatch.user.getCartDiscount()) * 0.05
    },
    getCartTip() {
      return dispatch.user.getCartSubTotal() * dispatch.user.getTip()
    },
    getCartTotal() {
      let deliveryFee = 0
      if (dispatch.user.getOrderType() === 'Delivery') {
        deliveryFee = dispatch.restaurant.getDeliveryFee()
      }
      return (
        dispatch.user.getCartSubTotal() -
        dispatch.user.getCartDiscount() +
        dispatch.user.getCartTax() +
        dispatch.user.getCartTip() +
        deliveryFee
      )
    },
    getReviewOrderId() {
      return getState().user.reviewOrderId || ''
    },
    getPoints() {
      return getState().user.points || 0
    },
    getPointsMax() {
      const points = dispatch.user.getPoints()
      if (points <= 1000) {
        return 1000
      }
      return (Math.floor((points - 1) / 1000) + 1) * 1000
    },
    getPointsWithPromoApplied() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)
      let pointsRedeemedTotal = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalRequiredPoints > 0) {
          pointsRedeemedTotal += promoDetails.totalRequiredPoints
        }
      }

      return dispatch.user.getPoints() - pointsRedeemedTotal
    },
    getOrderHistoryRange({ fromDate, toDate, limit = 100 }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const userId = dispatch.user.getUserId()
      return api.orders.getOrderHistoryRange({
        restaurantId,
        userId,
        fromDate,
        toDate,
        limit,
      })
    },
    getNotes() {
      return getState().user.notes
    },
    getOrderType() {
      return getState().user.orderType
    },
    getTableId() {
      return getState().user.tableId
    },
    getTip() {
      return getState().user.tip
    },
    async changeUserInfo({ name, phoneNumber }) {
      const userInfo = {}
      const nameTrimmed = name && name.trim()
      const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)

      if (nameTrimmed) {
        userInfo.name = nameTrimmed
        if (nameTrimmed.length > 18) {
          throw new Error('Name is too long')
        }
      }
      if (numerOnlyPhoneNumber) {
        if (!numerOnlyPhoneNumber || numerOnlyPhoneNumber.length !== 10) {
          throw new Error('Incorrect Phone Number. Please enter format of 7783334444')
        }
        userInfo.phoneNumber = numerOnlyPhoneNumber
      }
      if (dispatch.user.getIsLoggedIn() && !isEmpty(userInfo)) {
        return api.user.updateUser(dispatch.user.getUserId(), userInfo)
      }
    },
    changeUserAddress(address) {
      return api.user.updateUser(dispatch.user.getUserId(), address)
    },
    signInWithEmailAndPassword({ email, password, onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email) {
          reject(new Error('Email cannot be empty'))
          return
        }
        if (!password) {
          reject(new Error('Password cannot be empty'))
          return
        }
        dispatch.user.setIsLoggingIn(true)
        try {
          api.auth
            .signInWithEmailAndPassword(email, password)
            .then(res => {
              const uid = res.uid || get(res, 'user.uid')
              dispatch({ type: TYPES.ANALYTICS.LOGIN_SUCCESS, id: uid, email })
              onSuccess && onSuccess(res)
              dispatch.user.setIsLoggingIn(false)
              resolve(`Logged in as ${email}`)
            })
            .catch(error => {
              dispatch({ type: TYPES.ANALYTICS.LOGIN_FAIL, error, email })
              dispatch.user.setIsLoggingIn(false)
              reject(error)
            })
        } catch (e) {
          dispatch({ type: TYPES.ANALYTICS.ERROR, error: e, email })
          dispatch.user.setIsLoggingIn(false)
          reject(e)
        }
      })
    },
    createUserWithEmailAndPassword({ email, password, phoneNumber, name = '', onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email || email.length === 0) {
          reject(new Error('Email cannot be empty'))
          return
        }
        if (!password || password.length === 0) {
          reject(new Error('Password cannot be empty'))
          return
        }
        if (!name || name.trim().length === 0) {
          reject(new Error('Name cannot be empty'))
          return
        }
        const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)
        if (!numerOnlyPhoneNumber || numerOnlyPhoneNumber.length !== 10) {
          reject(new Error('Incorrect Phone Number. Please enter format of 7783334444'))
          return
        }
        dispatch.user.setIsLoggingIn(true)
        api.auth
          .createUserWithEmailAndPassword(email, password)
          .then(res => {
            const uid = res.uid || get(res, 'user.uid')
            const userData = {
              id: uid,
              email: email.toLowerCase(),
              name: name.trim(),
              role: 'customer',
              phoneNumber: numerOnlyPhoneNumber,
            }
            dispatch({ type: TYPES.ANALYTICS.REGISTER_SUCCESS, ...userData })
            return api.user.createUser(uid, userData)
          })
          .then(() => {
            onSuccess && onSuccess()
            resolve(`Registered as ${email}`)
          })
          .catch(err => {
            dispatch({ type: TYPES.ANALYTICS.REGISTER_FAIL, error: err, email })
            dispatch.user._unsetUser()
            reject(err)
          })
          .finally(() => {
            dispatch.user.setIsLoggingIn(false)
          })
      })
    },
    clearCart() {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: DEFAULT_STATE.cart,
          choicesCart: DEFAULT_STATE.choicesCart,
          promos: DEFAULT_STATE.promos,
        })
      } else {
        dispatch.user._clearCart()
        dispatch.user._clearPromos()
      }
      dispatch.user._clearNotes()
    },
    setCart(cartToSet) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: cartToSet,
        })
      } else {
        dispatch.user._setCart(cartToSet)
      }
    },
    setChoicesCart(cartToSet) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: cartToSet,
        })
      } else {
        dispatch.user._setChoicesCart(cartToSet)
      }
    },
    setAddress(userAddress) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return dispatch.user.changeUserAddress(userAddress)
      } else {
        dispatch.user._setAddress(userAddress)
        return Promise.resolve()
      }
    },
    setOrderType(orderType) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        return api.user.updateUserRestaurant(userId, restaurantId, { orderType })
      } else {
        dispatch.user._setOrderType(orderType)
        return Promise.resolve()
      }
    },
    addCartItem({ id, qty }) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const cart = dispatch.user.getCart()
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: {
            ...cart,
            [id]: {
              count: get(cart[id], 'count', 0) + qty,
            },
          },
        })
      } else {
        dispatch.user._addCartItem({ id, qty })
      }
    },
    subtractCartItem({ id }) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const cart = dispatch.user.getCart()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart:
            cart[id].count === 1
              ? omit(cart, id)
              : {
                  ...cart,
                  [id]: {
                    ...cart[id],
                    count: cart[id].count - 1,
                  },
                },
        })
      } else {
        dispatch.user._subtractCartItem({ id })
      }
    },
    addChoicesCartItem(product) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const choicesCart = dispatch.user.getChoicesCart()
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: [...choicesCart, product],
        })
      } else {
        dispatch.user._addChoicesCartItem(product)
      }
    },
    subtractChoicesCartItem(choiceIndex) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const choicesCart = dispatch.user.getChoicesCart()
        api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: choicesCart.reduce((prev, choice, index) => {
            if (index === choiceIndex && choice.count > 1) {
              prev.push(choice)
              choice.count--
            } else if (index !== choiceIndex) {
              prev.push(choice)
            }
            return prev
          }, []),
        })
      } else {
        dispatch.user._subtractChoicesCartItem(choiceIndex)
      }
    },
    getPromos() {
      return getState().user.promos
    },
    getValidPromosWithDetails() {
      const promos = dispatch.user.getPromos()
      const promoIds = Object.keys(promos)
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotal()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const isFirstOrder = dispatch.user.getIsFirstOrder()
      const points = dispatch.user.getPoints()

      const validPromoWithDetails = {}
      let remainingPoints = points

      for (const promoId of promoIds) {
        const rewardInfo = rewards[promoId]
        if (!rewardInfo) {
          continue
        }

        for (let remainingCount = promos[promoId].count; remainingCount > 0; remainingCount--) {
          const rewardDetails = utils.parseRewardInfo({
            rewardInfo,
            isFirstOrder,
            productsDetails,
            count: remainingCount,
          })

          const { valid } = utils.getIsRewardValid({
            isLoggedIn,
            rewardDetails,
            isFirstOrder,
            productsDetails,
            cartSubTotal,
            redeemedRewardCount: remainingCount,
            userPointsWithPromoApplied: remainingPoints - (rewardDetails.totalRequiredPoints || 0),
          })

          if (valid) {
            validPromoWithDetails[promoId] = { ...rewardDetails, id: promoId, count: remainingCount }
            break
          }
        }
      }

      return validPromoWithDetails
    },
    getRewardDetails({ rewardId }) {
      const promos = dispatch.user.getPromos()
      let count = 0
      if (promos[rewardId]) {
        count = promos[rewardId].count || 0
      }
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotal()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const userPointsWithPromoApplied = dispatch.user.getPointsWithPromoApplied()
      const isFirstOrder = dispatch.user.getIsFirstOrder()

      const rewardDetails = utils.parseRewardInfo({
        rewardInfo: rewards[rewardId],
        isFirstOrder,
        productsDetails,
        count,
      })
      const rewardRedeemable = utils.getIsRewardRedeemable({
        isLoggedIn,
        rewardDetails,
        isFirstOrder,
        productsDetails,
        cartSubTotal,
        redeemedRewardCount: count,
        userPointsWithPromoApplied,
      })

      return { ...rewardDetails, ...rewardRedeemable }
    },
    getRewardsWithDetails() {
      const rewards = dispatch.restaurant.getRewards()
      const rewardsWithDetails = {}
      const rewardIds = Object.keys(rewards)
      for (const rewardId of rewardIds) {
        const rewardDetails = dispatch.user.getRewardDetails({ rewardId })
        rewardsWithDetails[rewardId] = rewardDetails
        rewardsWithDetails[rewardId].id = rewardId
      }
      return rewardsWithDetails
    },
    getRewardsCount() {
      return Object.keys(dispatch.user.getRewardsWithDetails()).length
    },
    getRewardsWithDetailsSorted() {
      const rewardsWithDetails = dispatch.user.getRewardsWithDetails()
      return orderBy(Object.values(rewardsWithDetails), v => v.requiredPoints)
    },
    addPromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getPromos()
        const count = get(promos[code], 'count', 0)

        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          ['promos.' + code]: {
            id: code,
            count: count + 1,
          },
        })
      } else {
        // We don't allow adding promo while not logged in
        throw new Error('Please log in to redeem rewards!')
      }
    },
    removePromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getValidPromosWithDetails()
        const count = get(promos[code], 'count', 0)
        if (count <= 1) {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: DELETE_FIELD_VALUE,
          })
        } else {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: {
              id: code,
              count: count - 1,
            },
          })
        }
      }
    },
    clearPromos() {
      if (dispatch.user.getIsLoggedIn()) {
        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          promos: {},
        })
      }
    },
    async signOut() {
      try {
        dispatch.user.setIsLoggingOut(true)
        const res = await api.auth.signOut()
        dispatch.user._unsetUser()
        return res
      } finally {
        dispatch.user.setIsLoggingOut(false)
      }
    },
    getOrderDoc({ orderId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Orders')
        .doc(orderId)
    },
    getOrder({ orderId }) {
      return dispatch.user
        .getOrderDoc({ orderId })
        .get()
        .then(doc => {
          const orderData = doc.data()
          orderData.id = doc.id
          return orderData
        })
    },
    setNotificationToken({ notificationToken }) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return api.user.updateUser(userId, { notificationToken })
      }
    },
    updateOrderReview({ orderId, review }) {
      const userId = dispatch.user.getUserId()
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return new Promise((resolve, reject) => {
        api.orders
          .updateOrder({ restaurantId, orderId, order: { review } })
          .then(() => {
            api.user
              .updateUserRestaurant(userId, restaurantId, {
                reviewOrderId: null,
              })
              .then(resolve)
              .catch(reject)
          })
          .catch(reject)
      })
    },
    getLastUserOrder() {
      return getState().user.lastUserOrder
    },
    getIsLastUserOrderLoading() {
      return getState().user.loadingLastUserOrder
    },
    getIsFirstOrder() {
      const loading = dispatch.user.getIsLastUserOrderLoading()
      if (loading === false) {
        const lastUserOrder = dispatch.user.getLastUserOrder()
        return !get(lastUserOrder, 'id')
      } else {
        return false
      }
    },
    getUserDoc() {
      const userId = dispatch.user.getUserId()
      return db.collection('Users').doc(userId)
    },
    getUserRestaurantDoc() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return dispatch.user
        .getUserDoc()
        .collection('Restaurants')
        .doc(restaurantId)
    },
    getLastUserOrderDoc() {
      const userId = dispatch.user.getUserId()
      return dispatch.restaurant
        .getOrdersDoc()
        .where('userId', '==', userId)
        .orderBy('createdAt', 'desc')
        .limit(1)
    },
    subscribeAuth() {
      dispatch.user.setLoading(true)
      let unsubscribeUser = null
      let unsubscribeUserRestaurant = null
      let unsubscribeLastUserOrder = null
      firebaseAuth.onAuthStateChanged(user => {
        unsubscribeUser && unsubscribeUser()
        unsubscribeUserRestaurant && unsubscribeUserRestaurant()
        unsubscribeLastUserOrder && unsubscribeLastUserOrder()
        if (isEmpty(user)) {
          dispatch.user._unsetUser()
          dispatch.user.setLoading(false)
        } else {
          // Update createdAt and lastLogin
          dispatch.user
            .getUserDoc()
            .get()
            .then(doc => {
              const restaurantId = dispatch.restaurant.getRestaurantId()
              const currentTimestamp = utils.moment().valueOf()

              const userData = doc.data() || {}

              if (!userData.role || !userData.email || userData.email !== user.email) {
                api.user.createUser(user.uid, {
                  id: user.uid,
                  email: user.email,
                  role: 'customer',
                })
              }

              if (!userData.createdAt) {
                // User doesn't have createdAt
                api.user.createUser(user.uid, {
                  createdAt: currentTimestamp,
                  lastLogin: currentTimestamp,
                })
              } else {
                api.user.createUser(user.uid, {
                  lastLogin: currentTimestamp,
                })
              }

              if (
                !userData.restaurants ||
                !userData.restaurants[restaurantId] ||
                !userData.restaurants[restaurantId].createdAt
              ) {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      createdAt: currentTimestamp,
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              } else {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              }
            })
          unsubscribeUser = dispatch.user.getUserDoc().onSnapshot(
            snapshot => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userData = snapshot.data() || {}
                userData.id = snapshot.id
                dispatch.user._setUser(userData)
              }
              dispatch.user.setLoading(false)
            },
            error => {
              console.warn(error)
              dispatch.user.setLoading(false)
            }
          )
          dispatch.user.setLoadingUserRestaurant(true)
          unsubscribeUserRestaurant = dispatch.user.getUserRestaurantDoc().onSnapshot(
            snapshot => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userRestaurantData = snapshot.data()
                dispatch.user._setUser(userRestaurantData || {})
              }
              dispatch.user.setLoadingUserRestaurant(false)
            },
            error => {
              console.warn(error)
              dispatch.user.setLoadingUserRestaurant(false)
            }
          )
          dispatch.user.setLoadingLastUserOrder(true)
          unsubscribeLastUserOrder = dispatch.user.getLastUserOrderDoc().onSnapshot(snapshots => {
            snapshots.forEach(snapshot => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const lastOrderData = snapshot.data()
                lastOrderData.id = snapshot.id
                dispatch.user._setLastUserOrder(lastOrderData)
              }
            })
            dispatch.user.setLoadingLastUserOrder(false)
          }, console.warn)
        }
      })
    },
  }),
})
