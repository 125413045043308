export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562131054/Shaolin/foodGallery.jpg'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cflogo.png'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const email =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/email.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/logo.png'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/orderButton.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/downloadButton.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/hero.jpg'
export const background =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/background.jpg'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/rewardArrowRight.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/redeemButton.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/rewardDefault.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472195/tacofactory/rewardbg.png'
export const menuNotSelectedbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/menuNotSelectedbg.png'
export const menuSelectedbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569472194/tacofactory/menuSelectedbg.png'
