import React, { Component } from 'react'
import { css } from 'emotion'
import { CFSelect, IconLink } from 'components'
import { MEDIA_QUERY } from 'styles'
import { dispatch } from 'cf-web-app/store'

class NavbarView extends Component {
  render() {
    const { className } = this.props
    const classNames = css(styles.container, className)
    return (
      <nav className={classNames}>
        <IconLink
          label="MENU"
          to="/menu"
          icon="utensils"
          style={{ color: 'white' }}
        />
        <CFSelect selector={dispatch.user.getCartCount}>
          {cartCount => (
            <IconLink
              label={`CART (${cartCount})`}
              icon="shopping-cart"
              to="/cart"
              style={{ color: 'white' }}
            />
          )}
        </CFSelect>
        <CFSelect selector={dispatch.user.getIsLoggedIn}>
          {this.renderAuthLink}
        </CFSelect>
      </nav>
    )
  }
  renderAuthLink = isLoggedIn => {
    return isLoggedIn ? (
      <IconLink
        label="USER"
        to="/user"
        icon="user"
        style={{ color: 'white' }}
      />
    ) : (
      <IconLink
        label="LOGIN"
        icon="sign-in-alt"
        to="/auth/menu"
        style={{ color: 'white' }}
      />
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6rem',
    width: '42rem',
    [MEDIA_QUERY.SM]: {
      width: '95%',
    },
  }),
}

export default NavbarView
