import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, CFText, Space, PageHeader } from 'components'
import { hero, downloadButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <PageHeader />
        <CFView column className={styles.text}>
          <CFText heading2 bold h1 color="white" style={{ fontSize: 100 }}>
            NOW TAKING
          </CFText>
          <CFText heading2 bold h1 color="white" style={{ fontSize: 100 }}>
            ONLINE ORDERS
          </CFText>
        </CFView>
        <CFView className={styles.buttons}>
          {OrderNowButtonElement}
          <Space w2 />
          <div>
            <CFLink href="http://onelink.to/zzzg6g">
              <img
                src={downloadButton}
                className={styles.downloadButton}
                alt="Download App"
              />
            </CFLink>
          </div>
        </CFView>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    boxShadow: '0 .2rem .4rem rgba(0,0,0,0.7)',
    [MEDIA_QUERY.MD]: {
      backgroundPosition: '70%',
    },
  }),
  text: css({
    position: 'absolute',
    top: '28%',
    left: '4%',
    textShadow: '0 .2rem .4rem rgba(0,0,0,0.5)',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  buttons: css({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '5%',
    left: '3%',
    [MEDIA_QUERY.MD]: {
      width: '95%',
      flexDirection: 'column',
      justifyContent: 'center',
      bottom: '5%',
      right: 'auto',
    },
  }),
  downloadButton: css({
    width: '30rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  ctaText: css({
    fontSize: 38,
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
}

export default Hero
