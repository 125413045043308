import React, { Component } from 'react'
import { CFSelect } from 'cf-web-app/components'
import CartItem from './CartItem'
import ChoicesCartItem from './ChoicesCartItem'
import RewardItem from './RewardItem'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class Summary extends Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.SummaryView
            CartItemListElement={
              <CFSelect
                selector={{
                  cart: dispatch.user.getCartWithProductDetails,
                  choicesCart: dispatch.user.getChoicesCartWithProductDetails,
                  promos: dispatch.user.getValidPromosWithDetails,
                }}
              >
                {({ cart, choicesCart, promos }) => (
                  <div>
                    {Object.keys(cart).map(id => {
                      return <CartItem key={id} {...cart[id]} />
                    })}
                    {choicesCart.map((choice, index) => {
                      return (
                        <ChoicesCartItem
                          key={index}
                          index={index}
                          {...choice}
                        />
                      )
                    })}
                    {Object.keys(promos).map(id => {
                      return (
                        <RewardItem key={id} productId={id} {...promos[id]} />
                      )
                    })}
                  </div>
                )}
              </CFSelect>
            }
            CartTotalElement={
              <CFSelect
                selector={{
                  subTotal: dispatch.user.getCartSubTotal,
                  tax: dispatch.user.getCartTax,
                  total: dispatch.user.getCartTotal,
                  discount: dispatch.user.getCartDiscount,
                  orderType: dispatch.user.getOrderType,
                  tip: dispatch.user.getCartTip,
                  deliveryFee: dispatch.restaurant.getDeliveryFee,
                  firstOrderDiscount: dispatch.user.getFirstOrderDiscountAmount,
                }}
              >
                {({
                  subTotal,
                  tax,
                  total,
                  discount,
                  orderType,
                  deliveryFee,
                  tip,
                  firstOrderDiscount,
                }) => (
                  <Cart.SummaryTotalView
                    subTotalFormatted={(subTotal - discount).toFixed(2)}
                    taxFormatted={tax.toFixed(2)}
                    totalFormatted={total.toFixed(2)}
                    deliveryFeeFormatted={deliveryFee.toFixed(2)}
                    tipFormatted={tip.toFixed(2)}
                    firstOrderDiscount={firstOrderDiscount.toFixed(2)}
                    orderType={orderType}
                  />
                )}
              </CFSelect>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
}
